@font-face {
  font-family: "Lufga";
  src: url("./assets/fonts/lufga/LufgaSemiBold.ttf") format("truetype");
  src: url("./assets/fonts/lufga/LufgaMedium.ttf") format("truetype");
  src: url("./assets/fonts/lufga/LufgaSemiBold.woff") format("woff");
  src: url("./assets/fonts/lufga/LufgaMedium.woff") format("woff");
  src: url("./assets/fonts/lufga/LufgaSemiBold.woff2") format("woff2");
  src: url("./assets/fonts/lufga/LufgaMedium.woff2") format("woff2");
}

.App {
  margin: 0 auto;
  padding: 0;
  scroll-behavior: smooth;
  overflow: hidden;
}

body {
  background: rgba(30, 30, 30, 1);
}

* {
  scroll-behavior: smooth;
}

.homepage-container {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
}

.custom-btn {
  position: relative;
  transition: all 1.5s;
  overflow: hidden;
  transition: all 0.3s linear;
  background: #8b4ef5;
  color: #fff;
}

.custom-btn .custom-btn-inner {
  position: relative;
  z-index: 99;
}

.custom-btn:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #fff;
  transition: all 0.6s;
}

.custom-btn:hover {
  color: black;
}

.custom-btn:hover:after {
  width: 100%;
  background: #fff;
  color: black;
}

.custom-btn2 {
  overflow: hidden;
  position: relative;
  text-decoration: none;
  transition: 0.7s ease;
  z-index: 1;
  background: transparent;
  color: white;
  border: 1px solid #d9d9d9;
}

.custom-btn2:hover {
  border: 1px solid #8b4ef5;
  color: #fff;
}

.custom-btn2:before,
.custom-btn2:after {
  color: #fff;
  background: #8b4ef5;
  transition: 0.7s ease;
  content: "";
  position: absolute;
  z-index: -1;
  border: 1px solid #8b4ef5;
}

.middle-out:before {
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%;
}

.middle-out:hover:before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.bg-wrapper {
  background: rgba(17, 17, 20, 0.47);
}

/* header */

header {
  width: 100%;
  height: 104px;
  z-index: 999;
  top: 0;
  position: fixed;
  display: flex;
  align-items: center;
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  background: rgba(17, 17, 20, 0.47);
}

.shrink {
  height: 94px;
  background: rgba(17, 17, 20);
  z-index: 999;
}

/* hero */

.bg-hero {
  background: rgba(17, 17, 20, 0.47);
}

.hero-border {
  border-left: 1px solid rgba(255, 255, 255, 0.11);
}

.image-animation {
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
}

.image-animation:hover {
  transition: transform 0.5s ease-in-out;
  transform: scale(1.2);
}

.center-position {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.bg-copy-wrapper {
  background: rgba(0, 0, 0, 0.13);
}

.wrapped-text {
  word-break: break-all;
}

/* buy */

.bg-buy {
  background: rgba(17, 17, 20, 0.47);
}

/* footer */

.bg-footer {
  background: rgba(17, 17, 20, 0.47);
}

/* media queries */

@media screen and (min-width: 1920px) {
  .hero-icons-border {
    border-right: 1px solid black;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 1919px) and (min-width: 1801px) {
  .h-hero-title {
    font-size: 35px;
  }

  .hero-icons-border {
    border-right: 1px solid black;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  /* .bg-container {
    width: 100%;
    height: 100%;
    background-image: url("./assets/images/bg.png");
    background-size: 200vw;
  }

  @keyframes floating {
    from {
      background-position: 0 -40vh;
    }
    50% {
      background-position: -100vw -40vh;
    }
    to {
      background-position: 0 -40vh;
    }
  } */
}

@media screen and (max-width: 1800px) and (min-width: 1701px) {
  .container-padding {
    padding-left: 150px;
    padding-right: 150px;
  }
  .hero-resp-wrapper {
    column-gap: 100px;
  }
  .h-hero-title {
    font-size: 35px;
  }

  .hero-icons-border {
    border-right: 1px solid black;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  /* .bg-container {
    width: 100%;
    height: 100%;
    background-image: url("./assets/images/bg.png");
    background-size: 200vw;
  }

  @keyframes floating {
    from {
      background-position: 0 -40vh;
    }
    50% {
      background-position: -100vw -40vh;
    }
    to {
      background-position: 0 -40vh;
    }
  } */
}

@media screen and (max-width: 1700px) and (min-width: 1536px) {
  .container-padding {
    padding-left: 150px;
    padding-right: 150px;
  }
  .hero-resp-wrapper {
    column-gap: 100px;
  }
  .hero-left-resp {
    width: 40%;
  }
  .hero-right-resp {
    width: 60%;
  }
  .h-hero-title {
    font-size: 30px;
  }
  .hero-icons-border {
    border-right: 1px solid black;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  /* .bg-container {
    width: 100%;
    height: 100%;
    background-image: url("./assets/images/bg.png");
    background-size: 200vw;
  }

  @keyframes floating {
    from {
      background-position: 0 -40vh;
    }
    50% {
      background-position: -100vw -40vh;
    }
    to {
      background-position: 0 -40vh;
    }
  } */
}

@media screen and (max-width: 1535px) and (min-width: 1280px) {
  header {
    height: 90px;
  }
  .shrink {
    height: 85px;
  }

  .hero-left-resp {
    width: 40%;
  }
  .hero-right-resp {
    width: 60%;
  }
  .h-hero-title {
    font-size: 29px;
  }
  .hero-icons-border {
    border-right: 1px solid black;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  /* .bg-container {
    width: 100%;
    height: 100%;
    background-image: url("./assets/images/bg.png");
    background-size: 200vw;
  }

  @keyframes floating {
    from {
      background-position: 0 -30vh;
    }
    50% {
      background-position: -100vw -30vh;
    }
    to {
      background-position: 0 -30vh;
    }
  } */
}

@media screen and (max-width: 1279px) and (min-width: 1024px) {
  header {
    height: 90px;
  }

  .shrink {
    height: 85px;
  }
  .hero-left-resp {
    width: 43%;
  }
  .hero-right-resp {
    width: 54%;
  }
  .h-hero-title {
    font-size: 25px;
  }
  .hero-icons-border {
    border-right: 1px solid black;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  /* .bg-container {
    width: 100%;
    height: 100%;
    background-image: url("./assets/images/bg.png");
    background-size: 200vw;
  }

  @keyframes floating {
    from {
      background-position: 0 -30vh;
    }
    50% {
      background-position: -100vw -30vh;
    }
    to {
      background-position: 0 -30vh;
    }
  } */
}

@media screen and (max-width: 1023px) and (min-width: 900px) {
  header {
    height: 90px;
  }

  .shrink {
    height: 85px;
  }

  .hero-border {
    border-left: 0;
  }

  /* .bg-container {
    width: 100%;
    height: 100%;
    background-image: url("./assets/images/bg.png");
    background-size: 200vw;
  }

  @keyframes floating {
    from {
      background-position: 0 -20vh;
    }
    50% {
      background-position: -100vw -20vh;
    }
    to {
      background-position: 0 -20vh;
    }
  } */
}

@media screen and (max-width: 899px) and (min-width: 768px) {
  header {
    height: 90px;
  }

  .shrink {
    height: 85px;
  }

  .hero-border {
    border-left: 0;
  }

  /* .bg-container {
    width: 100%;
    height: 100%;
    background-image: url("./assets/images/bg.png");
    background-size: 200vw;
  }

  @keyframes floating {
    from {
      background-position: 0 -10vh;
    }
    50% {
      background-position: -100vw -10vh;
    }
    to {
      background-position: 0 -10vh;
    }
  } */
}

@media screen and (max-width: 767px) and (min-width: 426px) {
  header {
    height: 90px;
  }

  .shrink {
    height: 80px;
  }

  .hero-border {
    border-left: 0;
  }

  /* .bg-container {
    width: 100%;
    height: 100%;
    background-image: url("./assets/images/bg.png");
    background-size: 200vw;
  }

  @keyframes floating {
    from {
      background-position: 0 -10vh;
    }
    50% {
      background-position: -100vw -10vh;
    }
    to {
      background-position: 0 -10vh;
    }
  } */
}

@media screen and (max-width: 425px) {
  header {
    height: 90px;
  }

  .shrink {
    height: 80px;
  }

  .hero-border {
    border-left: 0;
  }

  /* .bg-container {
    width: 600px;
    height: 100%;
    background-image: url("./assets/images/bg-container1.png");
    background-size: cover;
    background-position: top;
  } */
}
